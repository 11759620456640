import * as React from "react"

const FeedbackCard = props => {
    const formatCity = str => {
        const lower = str.toLowerCase()
        return str.charAt(0).toUpperCase() + lower.slice(1)
    }

    const shortText = () => {
        return props.feedback.details.length > 110 && !props.full
    }
    return (
        <div
            className="feedback-carousel__item"
            role={'presentation'}
            onDragStart={props.onDragStart}
        >
            <div className="feedback-carousel__item-head">
                <div className="feedback-carousel__head">
                    <div className="feedback-carousel__writer">
                        <div className="feedback-carousel__writer-img">
                            <img
                                src={
                                    require(`./../images/flags/4x3/${props.feedback.client.country.iso.toLowerCase()}.svg`)
                                        .default
                                }
                                alt="avatar"
                            />
                            {/* <span v-else className="default-image"></span> */}
                        </div>
                        <div className="feedback-carousel__writer-info">
                            <span className="feedback-carousel__title">
                                Client
                            </span>
                            <span className="feedback-carousel__id">
                                {props.feedback.client.id}
                            </span>
                        </div>
                    </div>
                    <div className="feedback-carousel__rating">
                        <span>{props.feedback.rate_writer}</span>/
                        <span>10</span>
                    </div>
                </div>
                <div className="feedback-carousel__adress">
                    from
                    <i className="feedback-carousel__city">
                        {formatCity(props.feedback.client.city)}
                    </i>
                    ,
                    <i className="feedback-carousel__country">
                        {props.feedback.client.country.nicename}
                    </i>
                </div>
            </div>
            <div className="feedback-carousel__content">
                <p>
                    {shortText() ? (
                        <span>{props.feedback.details.slice(0, 110)}...</span>
                    ) : (
                        <span>{props.feedback.details}</span>
                    )}
                    {shortText() && (
                        <span
                            aria-hidden
                            className="feedback-carousel__card-more"
                            onClick={() => props.getFeedback(props.feedback)}
                        >
                            Read more
                        </span>
                    )}
                </p>
            </div>
            <div className="feedback-carousel__footer">
                <div className="feedback-carousel__footer-wrapper">
                    <div className="feedback-carousel__order">
                        <span className="feedback-carousel__title">order</span>
                        <span className="feedback-carousel__val">
                            #{props.feedback.orderid}
                        </span>
                    </div>
                    <a
                        href={`https://shop.genuinewriting.com/writers/profile/${props.feedback.sw_id}`}
                        className="feedback-carousel__writer-id"
                    >
                        <div className="feedback-carousel__writer-img">
                            <img
                                src={props.feedback.avatar && require(`../images/avatars/${props.feedback.avatar}`).default}
                                alt="avatar"
                            />
                            {/* <span v-else className="default-image"></span> */}
                        </div>
                        <div className="feedback-carousel-writer-info">
                            <span className="feedback-carousel__title">
                                writer
                            </span>
                            <span className="feedback-carousel__val">
                                {props.feedback.sw_id}
                            </span>
                        </div>
                    </a>
                </div>
                <a
                    href={`https://shop.genuinewriting.com/order/new?writer=${props.feedback.sw_id}`}
                    className="button button-border__main btn-feedback"
                >
                    HIRE WRITER
                </a>
            </div>
        </div>
    )
}

export default FeedbackCard
