import React, {useState} from "react";
import axios from "axios";

//COMPONENTS
import Layout from "@components/layout"
import Seo from "@components/Seo"
import Breadcrumbs from "@components/Breadcrumbs"
// import Testimonial from "@components/Testimonial";
import FeedbackCard from "@components/FeedbackCard";

//DATA
import testimonials from '@content/testimonials.json'

const TestimonialsPage = () => {
    const SEO = {
        title: 'Client Testimonials',
        meta: [
            {
                name: 'description',
                content: 'Check the feedback from our customers — they love the Custom Essay Writing service for students! Professional custom essay writers, ⏰24/7 support, 🔓full confidentiality, ✔️100% plagiarism-free! '
            },
            {
                name: 'og:description',
                content: 'Check the feedback from our customers — they love the Custom Essay Writing service for students! Professional custom essay writers, ⏰24/7 support, 🔓full confidentiality, ✔️100% plagiarism-free! '
            },
            {
                property: 'og:title',
                content: 'Client Testimonials'
            }
        ]
    }

    const steps = [
        {
            name: 'Client Testimonials',
            path: '/testimonials'
        }
    ]

    const [testimonialsList, setTestimonialsList] = useState([...testimonials.data])
    const [testimonialsNextPage, setTestimonialsNextPage] = useState(testimonials.next_page_url)

    const loadMore = async () => {
        const { data } = await axios.get(testimonialsNextPage)
        setTestimonialsList([
            ...testimonialsList,
            ...data.data
        ])
        setTestimonialsNextPage(data.next_page_url)
    }

    return (
        <Layout>
            <Seo {...SEO}></Seo>
            <div className="info-intro">
                <div className="wrapper">
                    <div className="info-intro--content">
                        <Breadcrumbs steps={steps}/>
                        <h1 className="page-intro__title">Our clients love us</h1>
                    </div>                    
                </div>
            </div>
            <div className="testimonials">
                <div className="wrapper">
                    <div className="row">
                        <div id="stickyBottomLimit" className="testimonials-content">
                            {testimonialsList.map((item, index) =>
                                <FeedbackCard key={index} feedback={item} full={true} />
                            )}
                        </div>
                    </div>

                    <div className="testimonials__more">
                        <button
                            disabled={!testimonialsNextPage}
                            className="button button-primary"
                            onClick={loadMore}
                        >
                            show more reviews
                        </button>
                    </div>

                    {/* <div className="testimonials-bottom"></div> */}
                </div>
            </div>
        </Layout>
    )
}

export default TestimonialsPage